
const burgerBtnOpened = document.querySelector('.header-opened-burger');
const burgerBtnClosed = document.querySelector('.header-closed-burger');

const burgerMenu = document.querySelector('.burger-menu');
const header = document.querySelector('.header');

const topBanner = document.querySelector('.top-banner');

function preventMotion(event) {
  // window.scrollTo(0, 0);
  event.preventDefault();
  event.stopPropagation();
}

const toggleBurgerMenu = () => {
  burgerBtnOpened.classList.toggle('hidden');
  burgerBtnClosed.classList.toggle('hidden');
  burgerMenu.classList.toggle('burger-menu_opened');

  if (burgerMenu.classList.contains('burger-menu_opened')) {
    window.onscroll = preventMotion;
    window.ontouchmove = preventMotion;
    header.classList.add('header_no-border');

    if (topBanner)
      topBanner.classList.add('banner-hidden');

  } else {
    if (topBanner)
      topBanner.classList.remove('banner-hidden');

    window.onscroll = null;
    window.ontouchmove = null;

    if (window.scrollY <= 5) {
      header.classList.remove('header_no-border');
    }
  }

  setTimeout(() => {
    document.body.classList.toggle('o-hidden');
  }, 300)
}

burgerBtnOpened.addEventListener('click', toggleBurgerMenu);
window.addEventListener('scroll', () => {
  const topBanner = document.querySelector('.top-banner');
  const offset = topBanner ? topBanner.offsetHeight + 5 : 5
  if (window.scrollY > offset) {
    header.classList.add('header_no-border');
    header.classList.add('header_scrolled');
  } else if (!burgerMenu.classList.contains('burger-menu_opened')) {
    header.classList.remove('header_no-border');
    header.classList.remove('header_scrolled');
  }
})
burgerBtnClosed.addEventListener('click', toggleBurgerMenu);