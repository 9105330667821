const articleLine = document.querySelector('.header-article-line');

let isHeaderBecomeFixed = 0;
window.addEventListener('scroll', function (e) {
  const currentPosition = window.scrollY;

  if (currentPosition < 30) {
    articleLine?.classList.remove('header-article-line_is-fixed');
  } else {
    articleLine?.classList.add('header-article-line_is-fixed');
  }
});